.block__content h1 {
  font-size: 32px;
  margin-top: 35px;
  margin-bottom: 10px;
}
.block__content h2 {
  font-size: 22px;
  margin-top: 15px;
  margin-bottom: 2px;
}
.block__content h3 {
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 2px;
}
.block__content h4 {
  font-size: 22px;
  margin-top: 45px;
  margin-bottom: 10px;
}
.block__content h5 {
  font-size: 18px;
}
.block__content ul {
  padding: unset;
}
.block__content ul li {
  list-style: disc;
  margin-bottom: 15px;
  margin-top: 18px;
}
.block__content a {
  color: blue;
  text-decoration: underline;
  word-wrap: break-word;
}
p {
  margin-top: 10px;
  margin-bottom: 20px;
}
